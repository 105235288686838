.contacts {
    margin: 2%;
}
.adresi {
}
.adresi1 {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
}
.line5 {
    width: 0;
    color: rgba(255, 255, 255, 0);
}
.imagecont {
    width: 80%;
    margin-left: 6%;

}
.title6 {
    margin-top: 3%;
}
@media (max-width: 768px) {
    .line5 {
        text-align: center;
        margin: 5%;
        width: 80%;
        color: rgba(0, 0, 0, 0.35);
    }
    .imagecont {
        margin-left: 6%;
    }
    .imagecont1 {
        margin-left: 7%;
    }
    .adresi1 {
        border-left:0;
    }
}
