.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease-in-out;
    z-index: 10000;
}
.scroll-to-top-button:hover {
    background-color: #bdbdbd;
    transform: translateY(-2px);
}
.scrollbut {
    font-size: 1.5em;
}
