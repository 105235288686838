.silki {
    display: inline-block;
    color: aliceblue; /* Цвет обычной ссылки */
    text-decoration: none; /* Убираем подчеркивание */
    cursor: pointer;
    margin-left: 30px;
    line-height: 2;
}

.silki:after {
    background-color: aliceblue; /* Цвет линии при наведении на нее курсора мыши */
    display: block;
    content: "";
    height: 2px; /* Высота линии */
    width: 0%;
    -webkit-transition: width .3s ease-in-out;
    -moz--transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
}
.silki:hover:after,
.silki:focus:after {
    width: 100%;
}
@media (max-width: 992px) {
    .silki {
        display: flex;
        justify-content: center;
        margin-left: 0px;
        font-size: 1.1em;
    }
    .silki:hover:after,
    .silki:focus:after {
        width: 0;
    }
}
.navbar-text {
    background: #00a9e3;
}


