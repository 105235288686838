.telegram-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: #0088cc;
    color: #fff;
    text-decoration: none;
    transition: all 0.5s ease-out;
    transition-delay: -0.4s;
    z-index: 20000;
}

.telegram-button:hover {
    background-color: #0077b5;
    transform: scale(1.1);
    width: 240px;
    height: 60px;
    border-radius: 100px;
    color: #fff;
}
.ictel {
    font-size: 2em;
}