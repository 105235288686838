@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

h1 {
  font-family: 'Philosopher', sans-serif;
}
h2 {
  font-family: 'Roboto Condensed', sans-serif;
}
p {
  font-family: 'Raleway', sans-serif;
}
body {
  background: rgba(118, 215, 245, 0.15);
  opacity:0;

}
.spin {
  background: #00a9e3;
  width: 50px;
  height: 50px;
}
